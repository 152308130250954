import { gql } from '@apollo/client';

export const BasicProfileFragment = gql`
  fragment BasicProfileFragment on Profile {
    id
    bio
    createdAt
    language
    loadingDepth
    picture
    proStatus
    pseudo
    sort
    status
    twitterUsername
  }
`;

export const FullProfileFragment = gql`
  fragment FullProfileFragment on Profile {
    id
    ...BasicProfileFragment
    user {
      id
    }
    isBan {
      createdAt
      dateEnd
      dateStart
      permanent
      shadow
      type
      hideBanMessage
    }
    disableMailNotif
    myRights {
      isMe
      isValidUser
      isSuperAdmin
      isWebsiteOwner
      isWebsiteAdministrator
      isWebsiteModerator
    }
    meta {
      countCommentAndMessage
      voteSended
      voteReceived
      rewards {
        id
        type
        name
      }
      gradeLabel
      grade
    }
    relation {
      id
      status
      message
      guest {
        id
      }
    }
  }
  ${BasicProfileFragment}
`;

export const userQuery = gql`
  query UserQuery {
    me {
      id
      email
      username
      language
      role
      delete
      validationEmailNeed
      profile {
        id
        isVip
        ...FullProfileFragment
      }
    }
  }
  ${FullProfileFragment}
`;

// Big query that all pages send in the layout.
// It's better to reuse the same query everywhere to benefit from the cache.
export const websiteQuery = gql`
  query WebsiteQuery {
    website {
      id
      name
      url
      color
      publicKey
      languages
      disableShareDiscussion
      disableMailNotif
      subscribeByTokenVip
      owner {
        id
      }
      administrators {
        id
      }
      moderators {
        id
      }
      labels {
        owner
        moderator
      }
      custom {
        image
        logo {
          image {
            id
            filename
          }
          imageActive
          text
          textActive
        }
        optInsActive
        optInsTrigger {
          active
          label
        }
        faviconArrayHtml
      }
      cgu {
        inputFrontActive
        inputFrontTxt
      }
      moderationContent {
        active
        permanent
        temporary
      }
      graphdebate {
        meta {
          common {
            title
            description
          }
          homepage {
            title
            description
          }
          topic {
            title
            description
            uri
          }
          category {
            title
            description
            uri
          }
          profile {
            title
            description
            uri
          }
          search {
            title
            description
            uri
          }
        }
        sso {
          externalUrlLogin
        }
        countPerPage
        countPerListPage
        loadingDepth
        lazyLoad
        embededFiles
        embededExternalLink
        edition {
          upload
          link
          formatting
          emoticons
        }
        hideTimestamp
        votesDiscreet
        firstBlocHomepage
        latestTopicsSortOrder
        sort
        group
      }
      login {
        email
        twitter
        google
        facebook
        linkedin
        order
      }
      pushWeb {
        oneSignal {
          active
          appId
          apiKey
        }
      }
    }
  }
`;
